<template>
    <client-page>
        <page-section class="page-section--first page-section--last">
            <template slot="tit">온라인쇼룸</template>

            <showroom-list-nav />

            <showroom-list-grid v-bind="{ items }" />

            <div class="btn-wrap btn-wrap--md" v-if="hasMoreItems" v-intersect.quiet="loadInfinite">
                <v-row align="center" justify="center" class="row--sm">
                    <v-col cols="12" sm="auto">
                        <v-btn outlined color="grey darken-4" class="v-size--xx-large w-100 min-w-sm-200px" v-bind="{ loading }" @click="loadMore">MORE VIEW</v-btn>
                    </v-col>
                </v-row>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import ShowroomListNav from "@/components/client/shop/showrooms/showroom-list-nav.vue";
import ShowroomListGrid from "@/components/client/shop/showrooms/showroom-list-grid.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        ShowroomListNav,
        ShowroomListGrid,
    },
    props: {
        code: { type: String, default: null },
    },
    data: () => ({
        showrooms: [],

        limit: 6,
        summary: { totalCount: 0 },

        loading: false,
        isLoadMore: true,
    }),
    computed: {
        items() {
            return [...this.showrooms];
        },

        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        hasMoreItems() {
            return this.page * this.limit < this.summary.totalCount;
        },

        headers() {
            let { page, skip, limit } = this;

            if (this.isLoadMore) {
                skip = this.showrooms.length;
                limit = limit * page - this.showrooms.length;
            }

            return { skip, limit };
        },

        params() {
            let { ...query } = this.$route.query;

            query.code = this.code;
            if (!query.code) delete query.code;

            return { ...query };
        },
    },
    mounted() {
        this.search();
    },
    watch: {
        code() {
            this.search();
        },
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.loading = false;

            this.showrooms = [];
            this.summary = { totalCount: 0 };
        },

        async search() {
            if (this.page == 1) await this.init();

            if (this.loading) return;
            else this.loading = true;

            try {
                let { headers, params } = this;
                var { summary, showrooms } = await api.v1.shop.showrooms.gets({
                    headers,
                    params,
                });

                // 로드모어
                if (this.isLoadMore) {
                    this.showrooms = this.showrooms.concat(showrooms);
                }
                // 일반목록
                else {
                    this.showrooms = this.showrooms;
                }

                this.summary = summary;
            } finally {
                this.$nextTick(() => (this.loading = false));
            }
        },

        loadMore() {
            if (this.loading) return;
            let { page = "1", ...query } = this.$route.query;
            query.page = +page + 1;
            this.$router.replace({ query });
        },

        loadInfinite(entries, observer, isIntersecting) {
            if (isIntersecting) this.loadMore();
        },
    },
};
</script>