var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last"
  }, [_c('template', {
    slot: "tit"
  }, [_vm._v("온라인쇼룸")]), _c('showroom-list-nav'), _c('showroom-list-grid', _vm._b({}, 'showroom-list-grid', {
    items: _vm.items
  }, false)), _vm.hasMoreItems ? _c('div', {
    directives: [{
      name: "intersect",
      rawName: "v-intersect.quiet",
      value: _vm.loadInfinite,
      expression: "loadInfinite",
      modifiers: {
        "quiet": true
      }
    }],
    staticClass: "btn-wrap btn-wrap--md"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 min-w-sm-200px",
    attrs: {
      "outlined": "",
      "color": "grey darken-4"
    },
    on: {
      "click": _vm.loadMore
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("MORE VIEW")])], 1)], 1)], 1) : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }