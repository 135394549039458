<template>
    <v-sheet ref="showroom-grid">
        <v-row>
            <template v-for="(col, colIndex) in cols">
                <v-col :key="`col-${colIndex}`">
                    <v-row>
                        <template v-for="(item, itemIndex) in col">
                            <v-col cols="12" :key="`col-${colIndex}-item-${itemIndex}`">
                                <v-card class="overflow-hidden">
                                    <showroom :value="item" />
                                </v-card>
                            </v-col>
                        </template>
                    </v-row>
                </v-col>
            </template>
        </v-row>
    </v-sheet>
</template>

<script>
import Showroom from "@/components/dumb/showroom/showroom.vue";

const BREAK_POINT_COLS_2 = 1080;

export default {
    components: {
        Showroom,
    },
    props: {
        items: { type: Array, default: () => [] }, // showrooms
    },
    data: () => ({
        clientWidth: undefined,
    }),
    computed: {
        cols() {
            const cols = [[], []];

            if (this.clientWidth >= BREAK_POINT_COLS_2) cols.push([]);

            for (const item of this.items) {
                const colsHeights = cols.reduce((heights, items, index) => {
                    if (heights[index] == undefined) heights[index] = 0;
                    for (const item of items) {
                        const { width, height } = item?.ratio || {};
                        heights[index] += height / width;
                    }
                    return heights;
                }, []);

                const index = colsHeights.reduce(
                    (result, height, index) => {
                        if (index == 0 || result.height > height) {
                            return { height, index };
                        } else return result;
                    },
                    { index: 0, height: 0 }
                )?.index;

                cols[index || 0].push(item);
            }

            return cols;
        },
    },
    created() {
        window.addEventListener("resize", this.setClientWidth);
    },
    destroyed() {
        window.removeEventListener("resize", this.setClientWidth);
    },
    mounted() {
        this.$nextTick(() => setTimeout(this.setClientWidth, 250));
    },
    methods: {
        setClientWidth() {
            const { clientWidth } = this.$refs["showroom-grid"]?.$el || {};
            this.clientWidth = clientWidth;
        },
    },
};
</script>

<style>
</style>
