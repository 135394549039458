var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    ref: "showroom-grid"
  }, [_c('v-row', [_vm._l(_vm.cols, function (col, colIndex) {
    return [_c('v-col', {
      key: `col-${colIndex}`
    }, [_c('v-row', [_vm._l(col, function (item, itemIndex) {
      return [_c('v-col', {
        key: `col-${colIndex}-item-${itemIndex}`,
        attrs: {
          "cols": "12"
        }
      }, [_c('v-card', {
        staticClass: "overflow-hidden"
      }, [_c('showroom', {
        attrs: {
          "value": item
        }
      })], 1)], 1)];
    })], 2)], 1)];
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }