<template>
    <div class="tabs-wrap">
        <v-tabs grow center-active show-arrows>
            <template v-for="{ text, value, to } in items">
                <v-tab :key="value" v-bind="{ value, to }" exact>{{ text }}</v-tab>
            </template>
        </v-tabs>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

const baseurl = "/shop/showrooms";

export default {
    data: () => ({
        loading: false,
    }),
    computed: {
        ...mapState(["setting"]),
        items() {
            return [{ text: "전체", value: null }, ...(this.setting.shop.showroomCodes || []).map((text) => ({ text, value: text }))].map((item) => {
                let to = baseurl;
                if (item.value) to = `${baseurl}/${item.value}`;
                return { ...item, to };
            });
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions(["getSetting__v1"]),
        async init() {
            if (this.loading) return;
            else this.loading = true;

            try {
                await this.getSetting__v1();
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-item-group {
        display: flex;
        width: 100%;
        .v-btn,
        .v-tab {
            width: 100%;
            min-width: auto !important;
            height: 50px !important;
            font-size: 14px !important;
            margin: 0;
            border-top: 1px solid var(--border-color) !important;
            &--active {
                color: var(--v-grey-darken4) !important;
            }
        }
    }
}
</style>
